import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgCreditCardUser from "../images/credit-card-user.jpg"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Is your coach company ready for Strong Customer Authentication (SCA)?" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">Is your coach company ready for Strong Customer Authentication (SCA)?</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">Is your coach company ready for Strong Customer Authentication (SCA)?</h1>
              </div>


                <div className="article">
                  <div className='article-inner'>
                    <h2>What is Strong Customer Authentication (SCA)?</h2>
                    <p>As part of the EU's Payment Services Directive (PSD2), SCA is a requirement to boost the security of online payments. From September 14th 2019, extra levels of authentication are now required at the point of transaction.</p>
                    <p>3D Secure 2.0 is the authentication tool of choice to collect SCA information and allow transactions to complete successfully.</p>
                    <p>What this actually means is payments that require SCA will involve the customer having to provide an extra form of authentication. This is likely to be in the form of a one time passcode sent via SMS by the bank/card issuer, or verification in the customer's banking app by fingerprint/face recognition.</p>

                    <h2>How will this affect my coach company?</h2>
                    <p><img src={imgCreditCardUser} alt='Credit card user' /></p>
                    <p>If you take payment for coach hire bookings online, or for example <Link className="link" to="/">sell tickets/passes for school services online</Link>, you will be affected.</p>

                    <h3>Coach hire bookings</h3>
                    <p>As one off payments for bookings are likely to be over €30, paying by credit/debit card online will require SCA. If your payment integration doesn't cater for this from September 14th 2019, your transactions will be declined.</p>

                    <h3>School services</h3>
                    <p>One off payments for items like annual or term tickets again would be impacted by SCA.</p>
                    <div style={{ background: 'rgb(229, 243, 255)', padding: '1em', marginBottom: '1em'}}>
                      <p style={{margin:0}}><strong>ShuttleID simplifies your school service operation.</strong> We handle ticket purchasing, issuing and validation - slashing admin, saving costs and removing the concern of SCA and PCI DSS so you can focus on your core business. <Link className="link" to='/'>Find out more</Link>.</p>
                    </div>
                    <p>Where it gets interesting is recurring payments if your business offers this as an option (e.g. annual ticket spread over 8 payments).</p>
                    <p>Subscriptions with a static amount (e.g. £100/month) are exempt from SCA from the second payment onwards. Often however, payments are requested variably. For example, 7 payments of £100 and 1 final payment of £50, or recurring term payments with the last term of year being cheaper.</p>
                    <p>All of these scenarios would be subject to SCA, for not only subscription by credit/debit card but standing orders also.</p>

                    <h2>What can I do to prevent disruption to my business?</h2>

                    <p>As the time is ticking for the SCA deadline, you need to start planning for the support of SCA, and maximising SCA exemption.</p>

                    <h3>Single payments</h3>
                    <p>If you take card payment online, ensuring that your payment integration is ready for SCA should be a priority.</p>
                    <p>Without support in place, transactions will be declined, and you may need to fallback to another mechanism for payment handling like phone sales (exempt from SCA) or bank transfer.</p>
                    <p>Failure to have a complete solution for SCA in place is likely to increase the cost of administration, by having to offer alternative methods of payment, and potentially bring further compliance burden (PCI DSS).</p>

                    <h3>Recurring payments</h3>
                    <p>If you offer recurring payments via credit/debit card online, you will need to ensure that your payment integration supports SCA for at least the initial transaction. It's preferable to make recurring payments as frictionless as possible, which is why you may want to reconsider your payment model.</p>
                    <p>For example, instead of having 1 payment at £50, 6 at £100 and 1 at £50, make all payments the same (8 payments of £87.50). In this scenario, SCA would only be applied once, rather than three times. This also applies to standing orders.</p>
                    <p>Another option to consider is direct debit. As direct debit is merchant initiated it's completely exempt from SCA.</p>
                    <div style={{ background: 'rgb(229, 243, 255)', padding: '1em', marginBottom: '1em'}}>
                      <p style={{margin:0}}><strong>ShuttleID supports recurring payments.</strong> Struggling to track payment from parents? We automate payment handling and enforce ticket validity without the admin overhead or compliance (SCA) worries. <Link className="link" to="/contact/">Find out more</Link>.</p>
                    </div>
                  </div>
                </div>
                <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
